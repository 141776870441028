// CartDetails.scss

.cart-details-container {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  max-width: 700px;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-height: 400px; /* Adjust the max-height as needed */
    overflow-y: auto;


    .cart-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: 1px solid #eee;
      padding: 10px;
      margin-bottom: 10px;

      .cart-item-img {
        flex: 1;
        max-width: 70px;
        margin-right: 10px;
      }

      .cart-item-details {
        flex: 2;
        display: flex;
        flex-direction: column;

        .cart-item-title {
          font-size: 1rem;
          margin-bottom: 5px;
        }

        .cart-item-price {
          font-size: 0.9rem;
          color: #555;
          margin-bottom: 5px;
        }

        .cart-item-quantity {
          font-size: 0.9rem;
          color: #555;

          button {
            background-color: #ff0000; // Red color
            color: #fff; // White text
            padding: 5px 10px; // Adjust padding for a smaller size
            border: none;
            cursor: pointer;
            font-size: 0.8rem; // Adjust font size
            margin: 0 5px; // Add margin for spacing between buttons
            transition: background-color 0.3s;
      
            &:hover {
              background-color: #cc0000; // Darker shade on hover
            }
          }
          
        }
      }

      .cart-item-delete {
        cursor: pointer;
        font-size: 1rem;
        color: #e74c3c; // Red color for delete icon
      }
    }
  }

  button {
    background-color: #4caf50;
    color: #fff;
    margin-bottom: 10px;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;

    &:hover {
      background-color: #4caf50;
    }
  }

  button + button {
    margin-left: 10px;
  }

  .Toastify__toast--success {
    background-color: #4caf50;
    color: #fff;
    font-size: 1rem;
    padding: 10px 15px;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    z-index: 1001;
  }

  .Toastify__toast-body {
    font-size: 16px;
    padding: 10px;
  }
}
