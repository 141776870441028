.navbar {
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  position: fixed;
  top: 0;
  width: 100%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  .merchant-name {
    h1 {
      color: #ec1202;
      margin: 0;
      font-size: 20px;
    }
  }

  .rightside {
    padding: 0;
    margin-right: 20px;
  }
}
