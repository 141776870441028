.food-details-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1050;
  width: 400px;
  max-width: 90%;
  border-radius: 8px;

  h2 {
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  .food-details-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /* Align content to the left */

    img {
      width: 100%;
      max-height: 200px;
      object-fit: cover;
      border-radius: 4px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 8px;
    }

    .price {
      color: red;
      font-weight: 600;
    }

    .requests-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      /* Align request items to the left */
      margin-bottom: 10px;

      div {
        margin-bottom: 5px;
        display: flex;
        align-items: center;
        /* Align checkbox and label vertically */
      }

      input[type="checkbox"] {
        margin-right: 5px;
      }
    }

    .button-container {
      display: flex;
      justify-content: space-between;
      /* Align buttons to the right */
      width: 100%;

      button {
        padding: 8px 16px;
        background-color: #4caf50;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        transition: background-color 0.3s;

        &:hover {
          background-color: #45a049;
        }

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }
}